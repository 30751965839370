import React, { Component } from 'react';
import './App.css';
import WebHits from './components/webHits';
import html2pdf from 'html2pdf.js';
// import html2pdf from './components/html2pdf.v0.9.3.bundle.min';



class App extends Component {
  state = {
    webHits: [],
    darkMode: localStorage.getItem("dark-mode") || "disabled"
  };

  componentDidMount() {
    // Fetch data for web hits
    fetch('https://api-this-is-thomas.tnepeters.com/resource')
      .then(res => res.json())
      .then((data) => {
        this.setState({ webHits: data });
      })
      .catch(console.log);

    // Add scroll event listeners
    window.addEventListener('scroll', this.handleScroll);
    
    // Check and apply initial dark mode setting
    if (this.state.darkMode === "enabled") {
      this.enableDarkMode();
    }
  }

  componentWillUnmount() {
    // Remove scroll event listeners when component unmounts
    window.removeEventListener('scroll', this.handleScroll);
  }

  enableDarkMode = () => {
    document.body.classList.add('dark-theme');
    localStorage.setItem("dark-mode", "enabled");
  };

  disableDarkMode = () => {
    document.body.classList.remove('dark-theme');
    localStorage.setItem("dark-mode", "disabled");
  };

  toggleDarkMode = () => {
    if (this.state.darkMode === "disabled") {
      this.enableDarkMode();
      this.setState({ darkMode: "enabled" });
    } else {
      this.disableDarkMode();
      this.setState({ darkMode: "disabled" });
    }
  };

  handleScroll = () => {
    const scrollTopButton = document.getElementById('scroll-top');
    if (window.scrollY >= 200) {
      scrollTopButton.classList.add('show-scroll');
    } else {
      scrollTopButton.classList.remove('show-scroll');
    }
  };

  render() {
    return (
        <div>
        <header className="l-header" id="header">
            {/*<!-- Nav menu -->*/}
            <nav className="nav bd-container">
                <a href="#top" className="nav_logo">Thomas Peters</a>
                <div className="nav_menu" id="nav-menu">
                    <ul className="nav_list">

                        <li className="nav_item">
                            <a href="#home" className="nav_link active-link">
                                <i className="fa-solid fa-house nav_icon"></i>Home
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#profile" className="nav_link">
                                <i className="fa-solid fa-user nav_icon"></i>Profile
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#skills" className="nav_link">
                                <i className="fa-solid fa-computer nav_icon"></i>Skills
                            </a>
                        </li>

                        {/*<!-- <li className="nav_item">
                            <a href="#languages" className="nav_link">
                                <i className="fa-solid fa-comments nav_icon"></i>Languages
                            </a>
                        </li> -->*/}

                        <li className="nav_item">
                            <a href="#experience" className="nav_link">
                                <i className="fa-solid fa-briefcase nav_icon"></i>Experiences
                            </a>
                        </li>

                        {/*<!-- <li className="nav_item">
                            <a href="#certificates" className="nav_link">
                                <i className="fa-solid fa-graduation-cap nav_icon"></i>Certificates
                            </a>
                        </li> -->*/}

                        <li className="nav_item">
                            <a href="#education" className="nav_link">
                                <i className="fa-solid fa-book-bookmark nav_icon"></i>Education
                            </a>
                        </li>

                        <li className="nav_item">
                            <a href="#interests" className="nav_link">
                                <i className="fa-solid fa-icons nav_icon"></i>Interests
                            </a>
                        </li>

                    </ul>
                </div>

                <div className="nav_toggle" id="nav-toggle">
                    <i className="fa-solid fa-bars"></i>
                </div>
            </nav>
        </header>

        <main className="l-main bd-container">
	    {/*<!-- Resume CV -->*/}
            <div className="resume" id="area-cv">
                <div className="resume_left">

	            {/*<!-- HOME -->*/}
                    <section className="home" id="home">
                        <div className="home_container section bd-grid">

                            <div className="home_data bd-grid">
                                <img src="profile.png" alt="Icon" className="home_img" id="home-img"></img>
	                        {/*<!--<img alt="Icon picture" className="home_img" id="home-img">-->*/}
                                <h1 className="home_title">THOMAS <b>PETERS</b></h1>
                                <h3 className="home_profession">Technology Enthusiast</h3>
                                {/* <div>
                                    <a download="" id="download-button" className="home_button-movil">Download</a>
                                </div> */}
                            </div>

			                <div className="home_address bd-grid">
                                <span className="home_information">
                                    <i className="fa-solid fa-location-dot home_icon"></i> Calgary - Alberta
                                </span>
                                <span className="home_information">
                                    <a href="mailto:thomas@tnepeters.com" className="home_link">
                                        <i className="fa-solid fa-envelope home_icon"></i> thomas@tnepeters.com
                                    </a>
                                </span>
                            </div>
                        </div>

                        {/*<!-- Theme change button -->*/}
                        {/* <!-- <i className="fa-solid fa-moon change-theme" title="Theme" id="theme-button"></i> --> */}
                        <i className="fa-solid fa-moon change-theme" title="Theme" id="theme-button" onClick={this.toggleDarkMode} ></i>

                	{/*<!-- Button to generate and download the pdf. Available for desktop. -->*/}
                        {/* <!-- <i className="fa-solid fa-download generate-pdf" title="Generate PDF" id="resume-button"></i> --> */}
                        <i className="fa-solid fa-download generate-pdf" title="Generate PDF" id="resume-button" onClick={this.generateResume}></i>

                    </section>

                    {/*<!-- SOCIAL -->*/}
                    <section className="social section">
                        <h2 className="section-title">Social</h2>

                        <div className="social_container bd-grid">
                            <a href="https://gitlab.com/SirMysterion" rel="noreferrer" target="_blank" className="social_link">
                                <i className="fa-brands fa-gitlab social_icon"></i> @SirMysterion
                            </a>
                            <a href="https://github.com/SirMysterion" rel="noreferrer" target="_blank" className="social_link">
                                <i className="fa-brands fa-github social_icon"></i> @SirMysterion
                            </a>
                            <a href="https://www.linkedin.com/in/peters-thomas/" rel="noreferrer" target="_blank" className="social_link">
                                <i className="fa-brands fa-linkedin social_icon"></i> peters-thomas
                            </a>
	                    {/*
                            {/*<!-- <a href="https://blog.sirmysterion.com/" rel="noreferrer" target="_blank" className="social_link">
                                <i className="fa-solid fa-rss social_icon"></i> Blog
                            </a> -->*/}
                        </div>
                    </section>

                    {/*<!-- PROFILE -->*/}
                    <section className="profile section" id="profile">
                        <h2 className="section-title">Profile</h2>

                        <p className="profile_description">
                            I'm a Linux and IPv6 enthusiast, managing a personal self-hosted homelab.
                            In this dynamic environment, I delve into diverse technologies ranging from AI/LLMs
                            and advanced networking to nested virtualization and smart home automation.
                            This practical setup allows me to blend my passion with hands-on experience,
                            keeping me at the forefront of technological innovation.
                        </p>
                    </section>

          	    {/*<!-- SKILLS  -->*/}
                    <section className="skills section" id="skills">
                        <h2 className="section-title">Skills</h2>
                        <div className="skills_container">
                            <div className="skills_content bd-grid">
                                <div className="skills_name">
                                    <span className="skills_text">Linux</span>
                                    <div className="skills_box">
                                        <span className="skills_progress" style={{ width: '95%' }}></span>
                                    </div>
                                </div>
                                <div className="skills_name">
                                    <span className="skills_text">Docker</span>
                                    <div className="skills_box">
                                        <span className="skills_progress" style={{ width: '95%' }}></span>
                                    </div>
                                </div>
                                <div className="skills_name">
                                    <span className="skills_text">Terraform</span>
                                    <div className="skills_box">
                                        <span className="skills_progress" style={{ width: '80%' }}></span>
                                    </div>
                                </div>
                                <div className="skills_name">
                                    <span className="skills_text">Git</span>
                                    <div className="skills_box">
                                        <span className="skills_progress" style={{ width: '75%' }}></span>
                                    </div>
                                </div>
                                <div className="skills_name">
                                    <span className="skills_text">Python</span>
                                    <div className="skills_box">
                                        <span className="skills_progress" style={{ width: '50%' }}></span>
                                    </div>
                                </div>
                                <div className="skills_name">
                                    <span className="skills_text">Cloud</span>
                                    <div className="skills_box">
                                        <span className="skills_progress" style={{ width: '80%' }}></span>
                                    </div>
                                </div>
                                <div className="skills_name">
                                    <span className="skills_text">Networking</span>
                                    <div className="skills_box">
                                        <span className="skills_progress" style={{ width: '80%' }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/*
                    {/*<!-- LANGUAGES -->*/}
                    {/*<!-- 
                    <section className="languages section" id="languages">
                        <h2 className="section-title">Languages</h2>

                        <div className="languages_container">
                            <ul className="languages_content bd-grid">
                                <li className="languages_name">
                                    <span className="languages_text">French</span>
                                    <span className="languages_stars">
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                    </span>
                                </li>
                                <li className="languages_name">
                                    <span className="languages_text">English</span>
                                    <span className="languages_stars">
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star"></i>
                                        <i className="fa-solid fa-star languages_stars_checked"></i>
                                        <i className="fa-solid fa-star languages_stars_checked"></i>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </section> -->*/}

                </div>

                <div className="resume_right">

                    {/*<!-- EXPERIENCE -->*/}
                    <section className="experience section" id="experience">
                        <h2 className="section-title">Experience</h2>

                        <div className="experience_container bd-grid">
                            <div className="experience_content">
                                <div className="experience_time">
                                    <span className="experience_rounder"></span>
                                    <span className="experience_line"></span>
                                </div>
                                <div className="experience_data bd-grid">
                                    <h3 className="experience_title">NETWORK AND SYSTEMS ADMINISTRATOR</h3>
                                    <span className="experience_company">Full Time | Digital Adrenaline Consulting - Calgary</span>
                                    <span className="experience_year">August 2020 - present</span>
                                    <p className="experience_description">
                                        Dedicated to providing comprehensive IT solutions both on-premises and in the cloud,
                                        while navigating the ever-evolving technological and security landscape to meet client needs effectively.
                                    </p>
                                </div>
                            </div>
                            <div className="experience_content">
                                <div className="experience_time">
                                    <span className="experience_rounder"></span>
                                    <span className="experience_line"></span>
                                </div>
                                <div className="experience_data bd-grid">
                                    <h3 className="experience_title">ASSOCIATE NETWORK ANALYST, NATIONAL OPERATIONS CENTER</h3>
                                    <span className="experience_company">Part-time | CDW Canada - Calgary</span>
                                    <span className="experience_year">Jan 2020 - Aug 2020</span>
                                    <p className="experience_description">
                                        Sev 1/2 Incident Management.<br></br>
                                        First level Triage and Technical Support<br></br>
                                        Automated Patching<br></br>
                                    </p>
                                </div>
                            </div>
                            <div className="experience_content">
                                <div className="experience_time">
                                    <span className="experience_rounder"></span>
                                    <span className="experience_line"></span>
                                </div>
                                <div className="experience_data bd-grid">
                                    <h3 className="experience_title">DESKTOP ANALYST</h3>
                                    <span className="experience_company">Contract | Scalar - Calgary</span>
                                    <span className="experience_year">Sept 2019 - Dec 2019</span>
                                    {/*<!-- <p className="experience_description">
                                        Work in this company dedicating the best responsibility in the area that
                                        corresponds, delivering the best results for the company and improving productivity.
                                    </p> -->*/}
                                </div>
                            </div>
                            <div className="experience_content">
                                <div className="experience_time">
                                    <span className="experience_rounder"></span>
                                    <span className="experience_line"></span>
                                </div>
                                <div className="experience_data bd-grid">
                                    <h3 className="experience_title">IT CONSULTANT</h3>
                                    <span className="experience_company">Self Employed | Calgary</span>
                                    <span className="experience_year">Oct 2018 - Dec 2018</span>
                                    {/*<!-- <p className="experience_description">
                                        Work in this company dedicating the best responsibility in the area that
                                        corresponds, delivering the best results for the company and improving productivity.
                                    </p> -->*/}
                                </div>
                            </div>
                            <div className="experience_content">
                                <div className="experience_time">
                                    <span className="experience_rounder"></span>
                                </div>
                                <div className="experience_data bd-grid">
                                    <h3 className="experience_title">IT SUPPORT SPECIALIST</h3>
                                    <span className="experience_company">Full Time | Nubirdz Computer Inc. - Calgary</span>
                                    <span className="experience_year">Nov 2015 - Oct 2018</span>
                                    {/*<!-- <p className="experience_description">
                                        Work in this company dedicating the best responsibility in the area that
                                        corresponds, delivering the best results for the company and improving productivity.
                                    </p> -->*/}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/*<!-- CERTIFICATES -->*/}
                    {/*<!-- <section className="certificate section" id="certificates">
                        <h2 className="section-title">Certificates</h2>

                        <div className="certificate_container bd-grid">
                            <div className="certificate_content">
                                <div className="certificate_item">
                                    <span className="certificate_rounder"></span>
                                </div>
                                <div className="certificate_data bd-grid">
                                    <h3 className="certificate_year">2020</h3>
                                    <span className="certificate_title">Diploma in Information Technology
                                        <span className="certificate_honours">with honours</span>
                                    </span>
                                </div>
                            </div>
                           </div>
                    </section> -->*/}

         	    {/*<!-- EDUCATION -->*/}
                    <section className="education section" id="education">
                        <h2 className="section-title">Education</h2>

                        <div className="education_container bd-grid">
                            <div className="education_content">
                                <div className="education_time">
                                    <span className="education_rounder"></span>
                                    {/*<!-- <span className="education_line"></span> -->*/}
                                </div>
                                <div className="education_data bd-grid">
                                    <h3 className="education_title">NETWORK SYSTEMS DIPLOMA</h3>
                                    <span className="education_studies">Southern Alberta Institute of Technology (SAIT) - Calgary</span>
                                    <span className="education_year">Jan 2019 - Aug 2020</span>
                                </div>
                            </div>
	                    {/*
                            {/*<!-- <div className="education_content">
                                <div className="education_time">
                                    <span className="education_rounder"></span>
                                    <span className="education_line"></span>
                                </div>
                                <div className="education_data bd-grid">
                                    <h3 className="education_title">Bachelor in Computer Science</h3>
                                    <span className="education_studies">Université Le Havre Normandie - France</span>
                                    <span className="education_year">2018 - 2021</span>
                                </div>
                            </div>
                            <div className="education_content">
                                <div className="education_time">
                                    <span className="education_rounder"></span>
                                </div>
                                <div className="education_data bd-grid">
                                    <h3 className="education_title">High School diploma in Sciences, speciality Mathematics</h3>
                                    <span className="education_studies">Lycée Guillaume Le Conquérant - France</span>
                                    <span className="education_year">2015 - 2018</span>
                                </div>
                            </div> -->*/}
                        </div>
                    </section>

           	    {/*<!-- INTERESTS -->*/}
                    <section className="interests section" id="interests">
                        <h2 className="section-title">Interests</h2>
                        <div className="interests_container bd-grid">
                            <div className="interests_content">
                                <i className="fab fa-linux interests_icon"></i>
                                <span className="interests_name">Linux</span>
                            </div>
                            <div className="interests_content">
                                <i className="fa-solid fa-network-wired interests_icon"></i>
                                <span className="interests_name">Networking</span>
                            </div>
                            <div className="interests_content">
                                <i className="fab fa-docker interests_icon"></i>
                                <span className="interests_name">Docker</span>
                            </div>
                            <div className="interests_content">
                                <i className="fas fa-print interests_icon"></i>
                                <span className="interests_name">3D Printing</span>
                            </div>
                            <div className="interests_content">
                                <i className="fas fa-paper-plane interests_icon"></i>
                                <span className="interests_name">Oragami</span>
                            </div>
                            <div className="interests_content">
                                <i className="fa-solid fa-mug-hot interests_icon"></i>
                                <span className="interests_name">Coffee</span>
                            </div>
                        </div>
                    </section>
                    <div id="web-hits">
                        <WebHits webHits={this.state.webHits} />
                    </div>
                </div>
            </div>
        </main>

        <a href="#top" className="scrolltop" id="scroll-top">
            <i className="fa-solid fa-arrow-up scrolltop_icon"></i>
        </a>

      </div>
    );
  }

  generateResume = () => {
    // Function to add scale class and hide WebHits for PDF generation
    const addScaleCVAndHideWebHits = () => {
      document.body.classList.add("scale-cv");
      const webHitsElement = document.getElementById('web-hits');
      if (webHitsElement) {
        webHitsElement.style.display = 'none';
      }
    };
  
    // Function to remove scale class and show WebHits after PDF generation
    const removeScaleCVAndShowWebHits = () => {
      document.body.classList.remove("scale-cv");
      const webHitsElement = document.getElementById('web-hits');
      if (webHitsElement) {
        webHitsElement.style.display = '';
      }
    };
  
    // Add scale and hide WebHits before generating PDF
    addScaleCVAndHideWebHits();
  
    // Define options for html2pdf
    const areaCV = document.getElementById('area-cv');
    const opt = {
      margin: 0,
      filename: document.body.classList.contains('dark-theme') ? 'myResumeCV-dark.pdf' : 'myResumeCV-light.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4, useCORS: true },
      jsPDF: { format: 'a4', orientation: 'portrait' }
    };
  
    // Generate PDF with html2pdf
    html2pdf().from(areaCV).set(opt).save().then(() => {
      // Remove the scale class and restore WebHits visibility after a delay
      setTimeout(removeScaleCVAndShowWebHits, 1000);
    });
  };
  
}

export default App;
