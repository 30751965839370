import React from 'react'

const WebHits = ({ webHits }) => {
    return (
      <section className="visitor section" id="INFO">
        <div className="card right-aligned">
          <i className="fa fa-user"></i>
          <p>Site Visitors: {webHits}</p>
        </div>

        {/* <div className="card right-aligned"> */}
        <div className="card">
          <p className="head">Deploy Source Links:</p>
          <a href="https://gitlab.com/sirmysterion/cloudresume-frontend" rel="noreferrer" target="_blank" className="source_link">
          FrontEnd
            {/* <i className="fa-brands fa-git-alt"></i> FrontEnd */}
          </a>
          <br></br>
          <a href="https://gitlab.com/sirmysterion/cloudresume-backend" rel="noreferrer" target="_blank" className="source_link">
            BackEnd
            {/* <i className="fa-brands fa-git-alt"></i> BackEnd */}
          </a>
        </div>
      </section>
    )
};

export default WebHits